<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"

  >
    <!-- context -->
    <vue-context ref="menu">
      <li>
        <b-link
            v-for="data in menuData"
            :key="data.text"
            class="d-flex align-items-center"
            @click="optionClicked(data.text,data.icon)"
        >
          <feather-icon
              :icon="data.icon"
              size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <div style="height: inherit">

      <div
          class="body-content-overlay"
          :class="{'show': mqShallShowLeftSidebar}"
          @click="mqShallShowLeftSidebar = false"
      />


      <div class="todo-app-list" ref="tree_card" style="">

        <!-- App Searchbar Header -->
        <div class="app-fixed-search d-flex align-items-center">

          <!-- Toggler -->
          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
                icon="MenuIcon"
                size="21"
                class="cursor-pointer"
                @click="mqShallShowLeftSidebar = true"
            />
          </div>


          <!-- Searchbar -->
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                  :value="searchQuery"
                  :placeholder="$t('Search file')"
                  @input="updateRouteQuery"
              />
            </b-input-group>
          </div>
          <b-button
              v-if="showPasteButton"
              size="sm"
              variant="success"
              @click="pasteFile(currentFolder)"
          >
            {{ $t('Paste here') }}
          </b-button>
          <!-- Dropdown -->
          <div class="dropdown">
            <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0 mr-1"
                right
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="resetSortAndNavigate">
                Reset Sort
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
                Sort A-Z
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
                Sort Z-A
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
                Sort Assignee
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
                Sort Due Date
              </b-dropdown-item>
            </b-dropdown>

          </div>
        </div>

        <!-- Todo List -->

        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="todo-task-list-wrapper list-group scroll-area"

        >
          <draggable
              v-model="tasks"
              handle=".draggable-task-handle"
              tag="ul"
              class="todo-task-list media-list"
              draggable="false"
          >


            <li
                v-if="parentFile.id >= -1"
                :key="parentFile.id"
                class="todo-item"
                :class="{ 'completed': false }"
                @click="fileClicked(parentFile)"
            >
              <feather-icon
                  icon="MoreVerticalIcon"
                  class="draggable-task-handle d-inline"
              />
              <div class="todo-title-wrapper">
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <Icon icon="bi:folder-fill" height="24" color="#008ec0"/>
                    <span class="todo-title">..</span>
                  </div>
                </div>

              </div>

            </li>


            <li
                v-for="file in treeData.children"
                :key="file.id"
                class="todo-item"
                :id="file.id"
                :class="{ 'completed': false }"
                @click="fileClicked(file)"
                @contextmenu.prevent="rightClick"
            >

              <feather-icon
                  icon="MoreVerticalIcon"
                  class="draggable-task-handle d-inline"
              />
              <div class="todo-title-wrapper" :id="file.id">
                <div class="todo-title-area" :id="file.id">
                  <div class="title-wrapper" :id="file.id">
                    <b-form-checkbox
                        :checked="false"
                        @click.native.stop
                        @change="updateTaskIsCompleted(task)"
                        v-if="false"
                    />


                    <Icon :icon="getIcon(file)" height="24" color="#008ec0" :id="file.id"/>


                    <span class="todo-title" :id="file.id" :nodeValue="file">{{ file.name }}</span>
                  </div>
                </div>
                <div class="todo-item-action">
                  <div class="badge-wrapper mr-1">
                    <b-badge
                        v-for="tag in file.tags"
                        :key="tag"
                        pill
                        :variant="`light-${resolveTagVariant(tag)}`"
                        class="text-capitalize"
                    >
                      {{ tag }}
                    </b-badge>
                  </div>
                  <small class="text-nowrap text-muted mr-1" :id="file.id">{{
                      formatDate(file.updated_at, {month: 'short', day: 'numeric'})
                    }}</small>

                </div>
              </div>

            </li>

          </draggable>

          <div
              class="no-results"
              :class="{'show': !tasks.length}"
          >
            <h5>No Items Found</h5>
          </div>
        </vue-perfect-scrollbar>

      </div>


      <!-- Task Handler -->
      <file-handler-sidebar
          v-model="isSidebarActive"
          :selectedFile="selectedFile"
          :folder="{id: currentFolder, tree: files}"
          :clear-task-data="clearTaskData"
          @remove-task="removeTask"
          @add-task="addTask"
          @update-task="refresh"
          :command="command"
          v-bind:command.sync="command"
      />

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-left">
        <files-left-sidebar
            :task-tags="taskTags"
            :is-task-handler-sidebar-active.sync="isSidebarActive"
            v-bind:command.sync="command"
            :class="{'show': mqShallShowLeftSidebar}"
            @close-left-sidebar="mqShallShowLeftSidebar = false"
            :command="command"
        />
      </portal>
    </div>
  </b-overlay>

</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar, BOverlay, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {formatDate, avatarText} from '@core/utils/filter'
import {useRouter} from '@core/utils/utils'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import FilesLeftSidebar from './FilesLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import FileHandlerSidebar from './FileHandlerSidebar.vue'
import {Icon} from '@iconify/vue2';
import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import {codeSearch} from "@/views/extensions/tree/code";
import i18n from "@/libs/i18n";
import ability from "@/libs/acl/ability";
import VueContext from 'vue-context'
import ToastificationContent from "@core/components/toastification/ToastificationContent";


export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BOverlay,
    draggable,
    VuePerfectScrollbar,
    Icon,
    // App SFC
    FilesLeftSidebar,
    FileHandlerSidebar,
    VueContext,
    BLink,
    BButton,
  },
  watch: {
    command: {
      handler: function (newValue) {
        console.log('watch command:' + newValue);
        if (this.command.startsWith("enterfolder:")) {
          let parts = this.command.split(':')
          let id = parts[1]

          let node = this.findNode(this.files, id)
          this.fileClicked(node)
        }
      },
      immediate: true,
    }
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const {route, router} = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const tasks = ref([])

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({name: route.name, query: currentRouteQuery}).catch(() => {
      })
    }

    const blankTask = {
      id: null,
      title: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      store.dispatch('app-todo/addTask', val)
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }
    const removeTask = () => {
      store.dispatch('app-todo/removeTask', {id: task.value.id})
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }
    const updateTask = taskData => {
      store.dispatch('app-todo/updateTask', {task: taskData})
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const taskTags = [
      {title: 'Team', color: 'primary', route: {name: 'apps-todo-tag', params: {tag: 'team'}}},
      {title: 'Low', color: 'success', route: {name: 'apps-todo-tag', params: {tag: 'low'}}},
      {title: 'Medium', color: 'warning', route: {name: 'apps-todo-tag', params: {tag: 'medium'}}},
      {title: 'High', color: 'danger', route: {name: 'apps-todo-tag', params: {tag: 'high'}}},
      {title: 'Update', color: 'info', route: {name: 'apps-todo-tag', params: {tag: 'update'}}},
    ]

    const resolveTagVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({name: route.name, query: currentRouteQuery})
    }

    const fetchTasks = () => {
      store.dispatch('app-todo/fetchTasks', {
        q: searchQuery.value,
        filter: router.currentRoute.params.filter,
        tag: router.currentRoute.params.tag,
        sortBy: sortBy.value,
      })
          .then(response => {
            tasks.value = response.data
          })
    }

    fetchTasks()

    const handleTaskClick = fileData => {
      console.log('CLICK', fileData)
      if (fileData.type === 'DIRECTORY') {
        this.currentFolder = fileData.id;

        this.treeData = fileData.children;
      } else {
        task.value = fileData
        isTaskHandlerSidebarActive.value = true
      }

    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      updateTask(taskData)
    }

    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      removeTask,
      addTask,
      updateTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
  mounted() {
    const userService = new UserService(useJwt)

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    this.refresh();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    rightClick(event, data) {
      console.log('event', event)


      this.rightClickedFile = this.findNode(this.files, event.target.id)
      console.log('found', this.rightClickedFile)
      console.log('id', event.target.id)
      console.log('files', this.files)

      this.$refs.menu.open(event, data)
    },
    fileClicked(file) {
      console.log('CLICK', file)
      if (file.type === 'DIRECTORY') {
        this.currentFolder = file.id;
        this.parentFile = this.findNode(this.files, file.parent_id)

        console.log('PARENT', this.parentFile)

        this.treeData = file;
      } else {
        this.info(file)
      }
    },
    info(file) {
      this.selectedFile = file
      this.command = "openFile"
      this.isSidebarActive = true
    },
    pasteFile(destination_id) {
      console.log('pasted file parent: ', this.selectedFile.parent_id)
      console.log('new parent: ', destination_id)

      const data = {
        file: this.selectedFile,
        new_parent_id: destination_id
      };
      const userService = new UserService(useJwt)

      userService.move_file(this.selectedFile.id, data).then(response => {
        if (response.data.status === "success") {
          console.log("response", response.data)
          this.refresh();

        } else {

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Generic error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: i18n.t('There was an error updating the file, please retry'),
            },
          })
        }
      });
      this.copyOperation = 'none'
    },
    optionClicked(text) {
      console.log('clicked', text)
      if (text === i18n.t('Info')) {
        console.log('opening')
        this.info(this.rightClickedFile)
      } else if (text === i18n.t('Copy')) {
        console.log('Copy')
        this.selectedFile = this.rightClickedFile;
        this.copyOperation = 'copy'
      } else if (text === i18n.t('Cut')) {
        console.log('Cut')
        this.selectedFile = this.rightClickedFile;
        this.copyOperation = 'cut'
      } else if (text === i18n.t('Paste')) {
        console.log('Paste', this.selectedFile)
        console.log('Operation', this.copyOperation)
        this.pasteFile(this.rightClickedFile.id)
      } else if (text === i18n.t('Delete file')) {
        console.log('Delete', this.rightClickedFile)
        this.deleteFile(this.rightClickedFile)
      } else {
        console.log('not opening')

      }
    },
    deleteFile(file) {
      console.log('delete')
      let confirmed = false;
      let message = i18n.t('You are deleting') + ": \"" + file.name + "\". " + i18n.t("Are you sure?");
      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const userService = new UserService(useJwt)

              userService.delete_file(file.id).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('File deleted'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: i18n.t('File deleted with success'),
                  },
                })
                this.refresh();
              })
            }
          })

    },
    getIcon(file) {
      if (file.type === "DIRECTORY") {
        return "bi:folder-fill"
      } else if (file.extension === "zip") {
        return "file-zip-fill"
      } else if (file.extension === "jpeg") {
        return "bi:filetype-jpg"
      } else {
        return "bi:filetype-" + file.extension
      }
    },

    findNode: function (files, searched) {
      let found = null;
      for (const file of files) {
        console.log("checking file id vs searched: " + file.id + " - " + searched)
        if (file.id == searched) {
          return file;
        } else {
          console.log("not match:", file.id)

          found = this.findNode(file.children, searched)
          if (found)
            return found;
        }
      }
      return false;
    },

    refresh() {
      console.log("files (tree) refresh")

      this.showLoading = true;
      const userService = new UserService(useJwt)
      let company_id = store.state.userCompany.id;
      if (this.currentFolder === -1) {
        this.currentFolder = this.$route.params.parent_id ? this.$route.params.parent_id : -1;
      }

      userService.files_tree(company_id, {}).then(response => {
        this.files = response.data.data.tree
        const all = response.data.data.all
        this.treeData = response.data.data.tree;


        this.treeData = this.findNode(this.files, this.currentFolder)


        console.log('files', this.files)
        this.showLoading = false;
      })

    },
  },
  computed: {
    showPasteButton() {
      return (this.copyOperation !== 'none' && this.selectedFile.parent_id != this.currentFolder)
    },
    menuData() {
      console.log('menudata rightClickedFile', this.rightClickedFile)
      if (this.editDisabled){
        return [
          {icon: 'FileIcon', text: i18n.t('Info')},
          {icon: 'XIcon', text: i18n.t('Close')},
        ]
      }else if (this.copyOperation === 'none' || this.rightClickedFile.type !== 'DIRECTORY') {
        return [
          {icon: 'FileIcon', text: i18n.t('Info')},
          {icon: 'ScissorsIcon', text: i18n.t('Cut')},
          {icon: 'Trash2Icon', text: i18n.t('Delete file')},
          {icon: 'XIcon', text: i18n.t('Close')},
        ]
      } else {
        return [
          {icon: 'ClipboardIcon', text: i18n.t('Paste')},
          {icon: 'FileIcon', text: i18n.t('Info')},
          {icon: 'ScissorsIcon', text: i18n.t('Cut')},
          {icon: 'Trash2Icon', text: i18n.t('Delete file')},
          {icon: 'XIcon', text: i18n.t('Close')},
        ]
      }

    }
  },
  data() {
    return {
      codeSearch,
      searchword: '',
      children: [],
      treeData: [
        {
          name: i18n.t('Basic Files'),
          expanded: true,
          children: this.children
        },
      ],
      editDisabled: !ability.can("manage", "Basic Files"),
      files: [],
      currentFolder: -1,
      isSidebarActive: false,
      command: "openFile",
      selectedFileId: 0,
      selectedFile: {id: 0},
      showLoading: false,
      parentFile: {id: -2},
      rightClickedFile: null,
      copyOperation: 'none',

    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';
</style>
