<template>
  <div>
    <b-sidebar
        id="sidebar-task-handler"
        sidebar-class="sidebar-lg"
        :visible="isTaskHandlerSidebarActive"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
        @hidden="clearForm"
    >
      <template #default="{ hide }">

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1" v-if="openFile">
          <b-button
              v-if="selectedFile.id && selectedFile.type ==='FILE'"
              size="sm"
              variant="success"
              @click="download()"
          >
            {{ $t('Download') }}
          </b-button>
          <h5
              v-else
              class="mb-0"
          >
            {{ $t('Add File') }}
          </h5>
          <div>
            <feather-icon
                v-show="taskLocal.id"
                icon="TrashIcon"
                class="cursor-pointer"
                @click="$emit('remove-task'); hide();"
            />
            <feather-icon
                v-if="false"
                class="ml-1 cursor-pointer"
                icon="StarIcon"
                size="16"
                :class="{ 'text-warning': taskLocal.isImportant }"
                @click="taskLocal.isImportant = !taskLocal.isImportant"
            />
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
            #default="{ handleSubmitU }"
            ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
              class="p-2"
          >

            <!-- Title -->
            <validation-provider
                #default="validationContext"
                name="Name"
            >
              <b-form-group
                  :label="$t('Name')"
                  label-for="task-name"
                  v-if="openFile"
              >
                <b-form-input
                    id="task-name"
                    v-model="selectedFile.name"
                    :disabled="editDisabled"
                    autofocus
                    trim
                    :placeholder="$t('Name')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Folder name -->
            <validation-provider
                #default="validationContext"
                name="Folder name"
            >
              <b-form-group
                  :label="$t('Folder name')"
                  label-for="task-name"
                  v-if="addNewFolder"
              >
                <b-form-input
                    id="task-name"
                    v-model="newFolderName"
                    autofocus
                    trim
                    :placeholder="$t('Folder name')"
                    :disabled="addNewFile"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <!-- Updated Date -->


            <b-form-group
                label="Update Date"
                label-for="update-date"
                v-if="openFile"

            >
              <b-form-input
                  class="form-control"
                  disabled

                  :value="selectedFile.updated_at | moment('YYYY-MM-DD HH:mm:ss')"
              />


            </b-form-group>


            <!-- upload -->
            <b-form-group
                :label="$t('Upload file')"
                v-if="addNewFile"
                label-for="v-upload"
            >

              <b-form-file
                  id="extension"
                  v-model="file"
                  :placeholder="$t('Click to upload')"
              />
            </b-form-group>


            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="editDisabled"
                  @click.prevent.stop="handleSubmit()"

              >
                {{ openFile ? $t('Update') : $t('Add') }}
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <spacer height="20"/>
            <hr/>
            <div v-show="show_comments">
              <div v-for="comment in file_comments">
                <spacer height="25"/>

                <!-- Toggle Icon -->

                <b-row>
                  <b-col>
                    <h5 class="mb-0">
                      {{ comment.user.name }} {{ comment.user.surname }}
                    </h5>
                    <span class="mb-0 small">
                     {{ comment.updated_at | moment("YYYY-MM-DD HH:mm:ss") }}
                    </span>
                  </b-col>

                </b-row>
                <spacer height="5"/>

                <b-row>
                  <b-col><span class="mb-0" v-html="comment.text">
                </span>
                  </b-col>


                </b-row>

              </div>
              <spacer height="20"/>

              <!-- Description -->
              <b-form-group
                  :label="$t('New comment')"
                  label-for="task-description"
                  v-if="openFile"
              >
                <quill-editor
                    id="quil-content"
                    v-model="comment_text"
                    :options="editorOption"
                    class="border-bottom-0"
                />
                <div
                    id="quill-toolbar"
                    class="d-flex justify-content-end border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold"/>
                  <button class="ql-italic"/>
                  <button class="ql-underline"/>
                  <button class="ql-align"/>
                  <button class="ql-link"/>
                </div>
                <spacer height="20"/>

                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click.prevent.stop="submit_comment()"

                >
                  {{ $t('Send') }}
                </b-button>

              </b-form-group>

            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
  BFormFile,
  BRow,
  BCol,
  BFormCheckbox,
  BCard, BFormTextarea, BTable, BCardHeader, BCardTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email, url} from '@validations'
import {avatarText} from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import {toRefs} from '@vue/composition-api'
import {quillEditor} from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import useJwt from "@/auth/jwt/useJwt";
import UserService from "@/basic/userService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import {BCardActions} from "@core/components/b-card-actions";
import ability from "@/libs/acl/ability";
import EquipmentService from "@/basic/equipmentService";
import {environment} from "@/auth/environment";


export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BFormFile,
    BDropdown,
    BDropdownItem,
    flatPickr,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    quillEditor,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      taskLocal: {id: -1},
      newFolderName: "",
      vehicle_document: {description: "", delivery_date: "", expiration_date: "", id: 0},
      attachment: {filename: ""},
      allEditDisabled: false,
      permission_fields: [{key: 'name', label: i18n.t('Name'),}, {key: 'read', label: i18n.t('Read'),}, {
        key: 'write',
        label: i18n.t('Write')
      }],
      permissionsData: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: !ability.can("manage", "Basic Files"),
      file: null,
      file_comments: [],
      show_comments: false,
      filedata: null,
      comment_text: this.$route.params.id,
      uploadNewVersion: false,
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: i18n.t('New comment text'),
      }

    }
  },
  watch: {
    isTaskHandlerSidebarActive: {
      handler: function (newValue) {
        console.log('isTaskHandlerSidebarActive Current vaules:' + newValue);
        this.file_comments = []
        if (newValue) {
          this.loadComments()
        }
      },
      immediate: true,
    }
  },
  computed: {
    showUploadFile() {
      return this.vehicle_document_id === 'new' || this.uploadNewVersion;
    },

    addNewFile: function () {
      return this.command === 'addFile'
    },
    addNewFolder: function () {
      return this.command === 'addFolder'
    },
    openFile: function () {
      return this.command === 'openFile'
    },

    currentDocumentSection() {
      return {id: "-11", name: "beta"}
    }
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedFile: {
      type: Object,
      required: true,
    },
    folder: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    command: {
      type: String,
      required: false,
    }
  },
  methods: {
    refresh() {
      this.$emit('update:is-task-handler-sidebar-active', false)
      this.$emit('update-task')


    },

    loadComments() {
      console.log("loadComments on file id", this.selectedFile.id)

      const userService = new UserService(useJwt)
      userService.get_files_comments(this.selectedFile.id).then(response => {
        if (response.data.status === "success") {
          console.log("get_files_comments", response.data)
          this.file_comments = response.data.data.file_comments
          console.log("file_comments", this.file_comments)

          this.show_comments = true
        } else {


        }
      })
    },

    submit_comment() {
      let data = {
        text: this.comment_text,
      };
      const userService = new UserService(useJwt)
      userService.add_file_comment(this.selectedFile.id, data).then(response => {
        if (response.data.status === "success") {
          console.log("add_files_comment", response.data)
          this.file_comments = response.data.data.file_comments
          this.comment_text = ""

        } else {


        }
      })
    },


    clearForm() {


    },
    download() {
      const link = document.createElement("a");
      link.href = environment.baseURL + "/files/download/" + this.selectedFile.id + "/" + this.selectedFile.otp
      link.download = this.selectedFile.path;
      link.click();
      URL.revokeObjectURL(link.href);

    },


    async handleSubmit(e) {

      const userService = new UserService(useJwt)
      console.log('handleSubmit command', this.command)

      if (this.command === "addFolder") {
        console.log('handleSubmit newFolderName', this.newFolderName)
        console.log('handleSubmit parent', this.folder)
        let data = {
          parentFolder: this.folder.id,
          newFolderName: this.newFolderName,
        };

        userService.create_folder(this.userCompany.id, data).then(response => {
          if (response.data.status === "success") {
            console.log("response", response.data)
            this.newFolderName = ""
            this.$emit('update:command', 'addFolderCompleted');
            this.refresh();

          } else {

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Generic error'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: i18n.t('There was an error saving the attachment, please retry'),
              },
            })
          }

        });
      }  else if (this.command === "addFile") {
        console.log('handleSubmit file', this.file)
        console.log('handleSubmit parent', this.folder)

        if (this.file) {


          let filevalue = this.file;
          console.log('handleSubmit filevalue', filevalue)

          var filePath = filevalue.name;
          console.log('handleSubmit filePath', filevalue.name)


          let reader = new FileReader();

          reader.onload = (e) => {
            console.log('readAsDataURL', e.target.result);
            this.filedata = e.target.result
            const data_upload = {
              parentFolder: this.folder.id,
              filedata: this.filedata,
              filename: filePath
            };


            userService.upload_file(this.userCompany.id, data_upload).then(response => {
              if (response.data.status === "success") {
                console.log("response", response.data)
                this.refresh();

              } else {

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('Generic error'),
                    icon: 'AlertIcon',
                    variant: 'danger',
                    text: i18n.t('There was an error saving the attachment, please retry'),
                  },
                })
              }
            })
          };
          reader.readAsDataURL(this.file);


        }
      } else {
        const data = {
          file: this.selectedFile,
        };
        console.log('handleSubmit edit file', this.selectedFile)
        userService.update_file(this.selectedFile.id, data).then(response => {
          if (response.data.status === "success") {
            console.log("response", response.data)
            this.refresh();

          } else {

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Generic error'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: i18n.t('There was an error updating the file, please retry'),
              },
            })
          }
        })
      }


    },
  },
  mounted() {
    //this.refresh()
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
